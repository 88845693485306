import { useStaticQuery } from "gatsby";
import format from "date-fns/format/index";
import de from "date-fns/locale/de";
import { utcToZonedTime } from "date-fns-tz";
var useCmsEvents = function useCmsEvents() {
    var data = useStaticQuery("2076357383");
    var edges = data.allDatoCmsEvent.edges.filter(function (edge) {
        var now = new Date();
        var utcDate = new Date(edge.node.time).toISOString();
        return now <= new Date(utcDate);
    }).map(function (edge) {
        var utcDate = new Date(edge.node.time).toISOString();
        var zonedDate = utcToZonedTime(utcDate, "Europe/Berlin");
        var formattedDate = format(zonedDate, "dd. MMMM yyyy,  HH:mm", {
            locale: de
        });
        return {
            node: Object.assign({}, edge.node, {
                time: formattedDate
            })
        };
    });
    return {
        allDatoCmsEvent: {
            edges: edges
        }
    };
};
export default useCmsEvents;
